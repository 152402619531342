import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import classnames from 'classnames'

import { parseCountryCode } from 'lib/countries'
import { arrayParam, stringParam } from 'lib/query-helper'

import Badge from 'components/Badge'
import Button from 'components/Button'
import Icon from 'components/Icon'
import SwipeView from 'components/SwipeView'
import Pagination from 'components/Pagination'
import SquareImage from 'components/SquareImage'
import NoResultsFound from 'components/NoResultsFound'
import ProgressSpinner from 'components/ProgressSpinner'
import ModalContainer, { ModalContainerProps } from 'components/ModalContainer/ModalContainer'

import BuyerRequestRecommendedSellersButton from '../BuyerRequestRecommendedSellersButton'
import RepRequestRecommendedSellersButton from '../RepRequestRecommendedSellersButton'
import SellerPreviewProductSearch from '../SellerPreviewProductSearch'
import SellerPageProductSort from 'modules/marketplace/catalog/components/SellerPageProductSort'
import { SellersListProps } from '../BuyerRequestRecommendedSellersButton/BuyerRequestRecommendedSellersButton'

import GET_SELLER_PREVIEW_CATALOG_DATA from './graphql/GetSellerPreviewCatalogData.graphql'
import {
  GetSellerPreviewCatalogData,
  GetSellerPreviewCatalogDataVariables,
  GetSellerPreviewCatalogData_marketplaceSeller_publishedProducts_nodes
} from './graphql/__generated__/GetSellerPreviewCatalogData'
import { GetRecommendedSellersForBuyer_currentBuyer_recommendedSellers } from 'modules/recommendations/pages/SellerRecommendationPage/graphql/__generated__/GetRecommendedSellersForBuyer'
import { GetRecommendedSellersForRep_currentRep_recommendedSellers } from 'modules/recommendations/pages/SellerRecommendationPage/graphql/__generated__/GetRecommendedSellersForRep'
import {
  AccountableTypeEnum,
  PricingRequestSourceEnum,
  ProductSortingEnum,
  RepPricingRequestSourceEnum,
  SortDirectionEnum
} from '../../../../../__generated__/globalTypes'

import styles from './SellerPreviewModal.module.css'

const PRODUCTS_PER_PAGE = 8
const MOBILE_SCREEN_WIDTH = 767

type productQueryVariablesType = Omit<GetSellerPreviewCatalogDataVariables, 'sellerId'>

const getProductQueryVariables = (query: ParsedUrlQuery) => {
  /* Parse Sorting Query strings
   * Perhaps an inelegant solution, but avoids changes to <ButtonDropdown />
   * and allows us to obfuscate how products are actually being fetched.
   * Also makes for nice and compact query strings in the URL
   */
  let sortBy
  let sortDirection
  switch (query.sort) {
    case 'newest':
      sortBy = ProductSortingEnum.CREATED_AT
      sortDirection = SortDirectionEnum.DESC
      break
    case 'az':
      sortBy = ProductSortingEnum.PRODUCT_NAME
      sortDirection = SortDirectionEnum.ASC
      break
    case 'za':
      sortBy = ProductSortingEnum.PRODUCT_NAME
      sortDirection = SortDirectionEnum.DESC
      break
    default:
      // "Relevance"
      sortBy = null
      sortDirection = null
  }

  const productQueryVariables: productQueryVariablesType = {
    // Filtering
    search: stringParam(query.search),
    brandValueIds: arrayParam(query.brandValueIds),

    // Sorting
    sortBy,
    sortDirection,

    // Pagination
    page: parseInt(query.page as string) || 1,
    productsPerPage: PRODUCTS_PER_PAGE
  }

  // Remove empty filters from product query variables as GraphQL doesn't like sortBy or sortDirection with null or undefined as values
  for (const [k] of Object.entries(productQueryVariables)) {
    const key = k as keyof productQueryVariablesType
    if (!productQueryVariables[key]) {
      delete productQueryVariables[key]
    }
  }

  return productQueryVariables
}

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}

type SellerPreviewModalProps = {
  className?: string
  seller:
    | GetRecommendedSellersForBuyer_currentBuyer_recommendedSellers
    | GetRecommendedSellersForRep_currentRep_recommendedSellers
  accountableType: AccountableTypeEnum.BUYER | AccountableTypeEnum.REP
} & ModalContainerProps

const SellerPreviewModal = ({
  className,
  seller,
  accountableType,
  isOpen,
  onExit,
  ...rest
}: SellerPreviewModalProps) => {
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const [activeIndex, setActiveIndex] = useState(0)
  const [swiperProducts, setSwiperProducts] = useState<
    GetSellerPreviewCatalogData_marketplaceSeller_publishedProducts_nodes[]
  >([])

  const router = useRouter()
  const productQueryVariables = getProductQueryVariables(router.query)

  const { id, displayName, description, bannerLargeUrl, logoThumbUrl, location, topBrandValues, marketplaceCatalogs } =
    seller

  const { loading, data } = useQuery<GetSellerPreviewCatalogData, GetSellerPreviewCatalogDataVariables>(
    GET_SELLER_PREVIEW_CATALOG_DATA,
    {
      variables: {
        sellerId: id,
        ...productQueryVariables
      },
      skip: !isOpen
    }
  )

  const publishedProducts = data?.marketplaceSeller?.publishedProducts
  const publishedProductsCount = publishedProducts?.nodesCount ?? 0

  const sellersList: SellersListProps = [
    {
      sellerId: id,
      catalogIds: marketplaceCatalogs.nodes.map(catalog => catalog.id)
    }
  ]

  // Get window size on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!loading && publishedProducts) {
      const newProducts = publishedProducts.nodes

      setSwiperProducts(prevState => {
        if (prevState) {
          if (router.query.search || router.query.brandValueIds || router.query.sort) {
            // Return query param results
            return newProducts
          } else {
            // Return normal products list
            return [...new Set([...prevState, ...newProducts])]
          }
        } else {
          return newProducts
        }
      })
    }
  }, [loading, publishedProducts, router.query])

  const resetProductFilters = () => {
    const query = router.query

    if (query.search) {
      delete query.search
    }
    if (query.brandValueIds) {
      delete query.brandValueIds
    }
    if (query.sort) {
      delete query.sort
    }
    if (query.page) {
      delete query.page
    }

    router.push(
      {
        pathname: router.pathname,
        query
      },
      undefined,
      { shallow: true }
    )
  }

  const handleCloseModal = () => {
    resetProductFilters()
    setActiveIndex(0)
    setSwiperProducts([])
    onExit()
  }

  const showPreviousProducts = useCallback(() => {
    if (!publishedProducts?.hasPreviousPage) return

    const query = router.query
    const page = parseInt(query.page as string) || 1

    if (page === 1) {
      delete query.page
      router.push(
        {
          pathname: router.pathname,
          query
        },
        undefined,
        { shallow: true }
      )
      return
    }

    query.page = (page - 1).toString()
    router.push(
      {
        pathname: router.pathname,
        query
      },
      undefined,
      { shallow: true }
    )
  }, [publishedProducts?.hasPreviousPage, router])

  const showNextProducts = useCallback(() => {
    if (!publishedProducts?.hasNextPage) return

    const query = router.query
    const page = parseInt(query.page as string) || 1
    query.page = (page + 1).toString()
    router.push(
      {
        pathname: router.pathname,
        query
      },
      undefined,
      { shallow: true }
    )
  }, [publishedProducts?.hasNextPage, router])

  const publishedProductsContent = (
    products: GetSellerPreviewCatalogData_marketplaceSeller_publishedProducts_nodes[]
  ) =>
    products.map((product, i) => {
      const { name, firstPicture } = product
      return (
        <div key={i} className={styles.previewProductItem}>
          <SquareImage className={styles.productImage} alt={name} src={firstPicture?.gridPhotoUrl} cover />
          <p className={styles.productName}>{name}</p>
        </div>
      )
    })

  return (
    <ModalContainer
      className={classnames(styles.modalContainer, className)}
      onExit={handleCloseModal}
      isOpen={isOpen}
      closeable
      {...rest}>
      <Button className={styles.closeModalButton} kind="transparent" icon="x" onClick={() => handleCloseModal()} />

      {bannerLargeUrl && (
        <div className={styles.bannerImage}>
          <img src=/_next/static/chunks/{bannerLargeUrl} alt={`Wholesale ${displayName}`} />
        </div>
      )}

      <div className={styles.sellerDetailsContainer}>
        <div className={styles.sellerDetails}>
          <div className={styles.leftInfo}>
            {logoThumbUrl && <img src=/_next/static/chunks/{logoThumbUrl} alt={`Wholesale ${displayName}`} className={styles.logoImage} />}

            <div className={styles.nameLocation}>
              <h4 className={styles.displayName}>{displayName}</h4>
              {seller.approvedPricingRequestCount > 20 && (
                <div className={styles.sellerMeta}>
                  <Icon className={styles.sellerMetaIcon} size={16} kind="star" />
                  {seller.approvedPricingRequestCount} Buyers
                </div>
              )}
              {location ? (
                <div className={styles.sellerMeta}>
                  <Icon className={styles.sellerMetaIcon} size={16} kind="map-pin" /> Based in {location?.city},{' '}
                  {location?.state}, {location?.country && parseCountryCode(location.country)}
                </div>
              ) : null}
            </div>
          </div>

          {accountableType === AccountableTypeEnum.BUYER && (
            <BuyerRequestRecommendedSellersButton
              className={styles.pricingRequestButton}
              sellersList={sellersList}
              source={PricingRequestSourceEnum.RECOMMENDATION}
              kind="primary"
              onPricingRequestSent={handleCloseModal}
            />
          )}

          {accountableType === AccountableTypeEnum.REP && (
            <RepRequestRecommendedSellersButton
              className={styles.pricingRequestButton}
              sellersList={sellersList}
              source={RepPricingRequestSourceEnum.RECOMMENDATION}
              kind="primary"
              onPricingRequestSent={handleCloseModal}
            />
          )}
        </div>

        <p className={styles.description}>{description}</p>

        {topBrandValues.length > 0 && (
          <div className={styles.badges}>
            {topBrandValues.map(bv => (
              <Badge kind="brand" style="tinted" className={styles.badge} key={bv.brandValue.name}>
                {bv.brandValue.name}
              </Badge>
            ))}
          </div>
        )}
      </div>

      <div className={styles.productSearchAndSortContainer}>
        <SellerPreviewProductSearch placeholder={`Search ${displayName} products`} />

        <SellerPageProductSort />
      </div>

      <div className={styles.productGridContainer}>
        <>
          {
            // Show products as Grid
            windowSize.innerWidth > MOBILE_SCREEN_WIDTH ? (
              loading ? (
                <ProgressSpinner className={styles.progressSpinner} />
              ) : publishedProducts && publishedProducts?.nodesCount > 0 ? (
                publishedProductsContent(publishedProducts?.nodes)
              ) : (
                publishedProductsCount === 0 && <NoResultsFound classNames={styles.noResultsFound} />
              )
            ) : // Show products as SwipeView
            loading && swiperProducts.length === 0 ? (
              <ProgressSpinner className={styles.progressSpinnerMobile} />
            ) : swiperProducts && swiperProducts.length > 0 ? (
              <SwipeView
                initialSlide={activeIndex}
                onActiveIndexChange={swiper => {
                  setActiveIndex(swiper.activeIndex)
                  if (swiper.activeIndex === swiperProducts.length - 5) {
                    showNextProducts()
                  }
                }}
                onReachEnd={swiper => {
                  if (swiper.isEnd && swiperProducts.length < publishedProductsCount) {
                    showNextProducts()
                  }
                }}
                className={styles.swiperContainer}
                spaceBetween={32}
                onReachBeginning={showPreviousProducts}>
                {publishedProductsContent(swiperProducts)}
              </SwipeView>
            ) : (
              publishedProductsCount === 0 && <NoResultsFound classNames={styles.noResultsFound} />
            )
          }
        </>
      </div>

      {windowSize.innerWidth > MOBILE_SCREEN_WIDTH && (
        <Pagination
          className={styles.pagination}
          baseUrl={router.asPath}
          origin={process.env.NEXT_PUBLIC_MARKETPLACE_URL}
          thingName="products"
          currentPage={router.query.page ? parseInt(router.query.page as string) : 1}
          totalPages={publishedProducts?.pagesCount ?? 1}
          perPage={PRODUCTS_PER_PAGE}
          showSummary={false}
          totalItems={publishedProducts?.nodesCount ?? 1}
        />
      )}
    </ModalContainer>
  )
}

export default SellerPreviewModal
